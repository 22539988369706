<template>
  <div>
    <MobileHeader v-show="!isHideHeaderAndFooter && !isHideHeader" />
    <slot />
    <MenuBottom v-if="!isHideHeaderAndFooter" />
    <client-only v-if="!isBotChromeLighthouse">
      <PageAccount v-if="isLogged" />
      <PageDeposit v-if="isLogged" />
      <PageWithdraw v-if="isLogged" />
      <ModalLogin v-if="!isLogged" />
      <ModalResetPassword v-if="!isLogged" />
      <ModalBlockGame v-if="isLogged" />
      <ModalChangeDisplayName v-if="isLogged" />
      <MiniGame />
    </client-only>
    <div class="opacity" @click="removeMenu" />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import MobileHeader from '~/components/mobile/header/index.vue'
import MenuBottom from '~/components/mobile/common/menu-bottom/index.vue'
import ModalLogin from '~/components/common/modals/login/index.vue'
import ModalResetPassword from '~/components/common/modals/reset-password/index.vue'
import PageAccount from '~/components/mobile/common/page-account/index.vue'
import PageDeposit from '~/components/mobile/common/page-deposit/index.vue'
import PageWithdraw from '~/components/mobile/common/page-withdraw/index.vue'
import MiniGame from '~/components/common/mini-game'
import { useAppStore } from '~/store/app'
import { useRefresh } from '~/composables/user/useRefresh'
import { useInitData } from '~/composables/user/useInitData'
import { PAGE_HIDE_HEADER_FOOTER_MB, PAGE_HIDE_HEADER_MB } from '~/constants/common'
import { useToggleMenu } from '~/composables/useToggleMenu'
import useModal from '~/composables/useModal'

const ModalBlockGame = defineAsyncComponent(() => import('~/components/desktop/modals/block-game/index.vue'))
const ModalChangeDisplayName = defineAsyncComponent(
  () => import('~/components/desktop/modals/change-display-name/index.vue')
)
const { $pinia } = useNuxtApp()
const route = useRoute()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const { isBotChromeLighthouse } = useAppStore()
const { refreshTokenInterval, refreshToken } = useRefresh()
const { fetchInitData, fetchPlan } = useInitData()
const { removeMenu } = useToggleMenu()
const { closeAllModal } = useModal()

onMounted(() => {
  refreshToken()
  if (isLogged.value) {
    userInitData()
    removeParams()
  }

  window.addEventListener('popstate', handlePopstate)

  setTimeout(() => {
    const modalIds = ['modalBlockGame', 'modalCancelPromotion', 'modalDisplayName']

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        const modalElement = entry.target as HTMLElement
        const modalId = modalElement.id
        if (entry.isIntersecting) {
          const bodyElement = document.querySelector('body')
          if (bodyElement) {
            disablePageScroll(bodyElement)
          }
        } else {
          enablePageScroll(document.querySelector('body'))
        }
      })
    }
    const observer = new IntersectionObserver(handleIntersection, { threshold: 0 })

    modalIds.forEach((modalId) => {
      const modalElement = document.getElementById(modalId)
      if (modalElement) {
        observer.observe(modalElement)
      }
    })
  }, 1500)
  window.requestAnimationFrame(() => window.scrollTo(0, 0))
})

onBeforeUnmount(() => {
  window.removeEventListener('popstate', handlePopstate)
})

watch(isLogged, () => {
  refreshToken()
  if (isLogged.value) {
    userInitData()
    removeParams()
  }
})

const userInitData = () => {
  refreshTokenInterval()
  fetchInitData()
  fetchPlan()
}

const handlePopstate = () => {
  // close all modals except modal authentication
  if (!route.query.popup) {
    closeAllModal()
    // remove style and class of body when opening modal
    const bodyElement = document.querySelector('body')
    bodyElement?.classList.remove('modal-open')
    bodyElement?.removeAttribute('style')
  }
}

const isHideHeaderAndFooter = computed(() => {
  return (
    PAGE_HIDE_HEADER_FOOTER_MB.includes(route?.name as string) || PAGE_HIDE_HEADER_FOOTER_MB.includes(route.fullPath)
  )
})

const isHideHeader = computed(() => {
  return PAGE_HIDE_HEADER_MB.includes(route?.name as string)
})

const removeParams = () => {
  const query = { ...route.query }
  delete query.popup
  delete query.token
  navigateTo({
    query: { ...query }
  })
}

watch(
  isHideHeaderAndFooter,
  (newValue) => {
    if (process.client) {
      document.body.classList.toggle('mobile-custom', isHideHeaderAndFooter.value)
    }
  },
  { immediate: true }
)
</script>
<style src="public/assets/scss/layout/mobile.scss" scoped lang="scss" />
